/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://s6empuzg5zforjk3zvsomzgnrq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2xjerxarqjhghp4r2t3mazq6eu",
    "aws_cognito_identity_pool_id": "ap-south-1:7160bdfc-b0ad-4705-ad38-6d54e60f6fa7",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_IdEGBEJLU",
    "aws_user_pools_web_client_id": "up735i1gg7cte7h0e8hj27rk2",
    "oauth": {},
    "aws_user_files_s3_bucket": "teledocsproofdocsdev232445-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
