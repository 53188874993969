import React from 'react'
import ReactDOM from 'react-dom';

export default ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <div >
    {/* <div style={{ padding: '2rem' }}> */}

    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">
        <div className="modal-header">
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p className="std">Terms of Use for Practitioners, Practices and Healthcare Providers for the use of CliniQ and the other Services provided by CliniVantage Healthcare Technologies.</p>
        <p className="tnc">By using the Subscription Services, you agree that you have read and understood these Terms of Use and you agree to be bound by these Terms of Use and use these Subscription Services in compliance with these Terms of Use. You expressly represent and warrant that you will not use these Subscription Services if you do not understand, agree to become a party to, and abide by all of the terms and conditions specified below. Any violation of these Terms of Use may result in legal liability upon you. Nothing in these Terms of Use should be construed to confer any rights to any third party or any other person. YOUR USE OF THE CliniVantage services MEANS YOU ARE CONSENTING TO THIS AGREEMENT.</p>
        <p className="tnc">CliniVantage provides healthcare solutions and is not responsible for and does not deal with any of patient managed by User through the solution or mobile applications and only provides Software to User through the platform and native mobile applications. To the extent User uses such software or downloads such software from the website, the software, will be deemed to be licensed to User by CliniVantage, for providing Subscription Services to User and enabling User to use those Software only. CliniVantage does not transfer either the title or the intellectual property rights to the Software and other its Subscription Services, and CliniVantgae (or its licensors) retain full and complete title to the Software as well as all intellectual property rights therein. User agrees to use the Subscription Services and the materials provided therein only for purposes that are permitted by: (a) this Agreement; and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions. Information provided by a User to CliniVanatage may be used, stored or re-published by CliniVantage or its affiliates even after the termination of these terms of Service.</p>
        <p className="tnc">CliniVantage offers its Subscription Services on as-is basis and has the sole right to modify any feature or customize them at its discretion and there shall be no obligation to honour customization requests of any User. The subscription fee hence charged is exclusive of any customization costs.</p>
        <p className="tnc">In the event the Software are not available due to apparent default at CliniVantage&rsquo;s end or are rendered unusable, CliniVantage may at its discretion extend the subscription period of the Practitioner only by such number of calendar days when the Subscription Services were not available. However, you shall agree that CliniVantage is not responsible and will not be held liable for the any failure of the intermediary services such as, internet connectivity failure or telephonic disconnections.</p>
        <p className="tnc">CliniVantage reserves the right to use all information captured in its Subscription Services in anonymized form for the purpose of its Subscription Services improvements, and providing analytics and businesses intelligence to the third parties.</p>
        <p className="tnc">CliniVantage automatically lists Practitioner information on its Subscription Services as per information added to a Practice using its Software. The information listed is displayed when End-Users search for Practitioners or Practices on the CliniVantage, and this information listed on the Subscription Services may be used by End-Users to request for appointments.</p>
        <p className="tnc">While CliniVantage makes every feasible effort to ensure a confirmed appointment for an End-User who requested an appointment on the Service, CliniVantage does not guarantee that the appointments will be confirmed in all cases. Further, CliniVantage has no liability if such appointment is confirmed but later cancelled by any of the End-Users, or the Practitioners are not available as per the given appointment time.</p>
        <p className="std" style={{ fontWeight: "bold", color: "#1e1e1e" }}><u>Payment, Fees and Taxes:</u></p>
        <p className="tnc">CliniVantage will charge an annual subscription fee for the use of the CliniQ Platform. In addition to the subscription fee, for all pre-paid appointments booked through the CliniQ App, CliniVantage will charge 5% of the fees as Processing Fees. These would cover Payment Gateway charges and fees for providing the Payment Clearing services to the doctors.</p>
        <p className="tnc">The User agrees to pay all subscription fees, consulting fees and other fees applicable to User&rsquo;s use of Subscription Services and the User shall not circumvent the fee structure.</p>
        <p className="tnc">CliniVantage reserves the right to modify the fee structure by providing a 30 (thirty) days&rsquo; prior notice, either by notice on the Subscription Services or through email to the authorized User, which shall be considered as valid and agreed communication. Upon the User not communicating any response to CliniVantage to such notice, CliniVantage shall apply the modified fee structure effective from the expiry of the said notice period.</p>
        <p className="tnc">Fees and charges shall be calculated solely based on records maintained by CliniVantage or its third party billing provider. No other information of any kind shall be acceptable by us or have any effect under this agreement. Decision of CliniVantage shall be final and binding in relation to any fees payable by Users.</p>
        <p className="std" style={{ fontWeight: "bold", color: "#1e1e1e" }}><u>Liability</u></p>
        <p className="tnc">CliniVantage shall not be responsible or liable in any manner to the Users for any losses, damage, injuries or expenses incurred by the Users as a result of any disclosures made by CliniVantage, where the User has consented to the making of disclosures by CliniVantage. If the User had revoked such consent under the terms of the privacy policy, then CliniVantage shall not be responsible or liable in any manner to the User for any losses, damage, injuries or expenses incurred by the User as a result of any disclosures made by CliniVantage prior to its actual receipt of such revocation.</p>
        <p className="tnc">CliniVantage assumes no responsibility, and shall not be liable for ways in which End-User data is used by Practitioners and other authorized users of Software at a Practice. It is the responsibility of the Practice alone to ensure that the End-User data either stored in Software or taken out from Software by printing or exporting to PDF, CSV or any other computer file format or data stored offline in mobile devices of users accessing Software through mobile applications published by CliniVantage, is used in compliance to local privacy laws applicable to the Practice&rsquo;s business transactions with End-Users.</p>
        <p className="std" style={{ fontWeight: "bold", color: "#1e1e1e" }}><u>Indemnity</u></p>
        <p className="tnc">User agrees to indemnify and hold harmless CliniVantage, its affiliates, officers, directors, employees, consultants, licensors, agents, and representatives from any and all third party vendors, from claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from his/her/ its access to or use of Software, violation of this Agreement, or infringement, or infringement by any other user of his/her/its account, of any intellectual property or other right of any person or entity. CliniVantage will notify you promptly of any such claim, loss, liability, or demand, and in addition to your foregoing obligations, you agree to provide us with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost.</p>
        <p className="std" style={{ fontWeight: "bold", color: "#1e1e1e" }}><u>Term, Termination and Disputes</u></p>
        <p className="tnc">This Agreement will remain in full force and effect while the User is a user of any of the Subscription Services in any form or capacity. The User can request for termination of his/her/its membership with CliniVantage at any time by providing 30 (thirty) days&rsquo; prior written notice to <a href="mailto:info@clinivantage.com">info@clinivantage.com</a>. &nbsp;During this 30-day period, CliniVantage will investigate and ascertain the fulfilment of any ongoing Subscription Services or pending dues related to subscription fees or any other fees by the User.</p>
        <p className="std" style={{ fontWeight: "bold", color: "#1e1e1e" }}><u>Severability &amp; Waiver</u></p>
        <p className="tnc">If any provision of this terms of use is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.</p>
        <div className="modal-header">
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>


  </div>, document.body
) : null;
