import React, { useState } from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createDoctor, sendSubmitEmailToDoc } from './graphql/mutations'
import awsconfig from './aws-exports';
import SavingSpinnerSvg from './SavingSpinnerSvg';
import SaveSuccessMessage from './SaveSuccessMessage'
import Autocomplete from './Autocomplete'
import specialityList from './specialityList'
import MandatoryStar from './MandatoryStar'
import Tnc from './Tnc'
import useModal from './useModal';

export default function DoctorRegistration() {
  const { isShowing, toggle } = useModal();
  const { register, handleSubmit, errors } = useForm();
  const [saving, updateSaving] = useState(false);
  const [showSuccess, udpateShowSuccess] = useState(false)
  const [specialityFromAuto, updateSpecialityFromAuto] = useState("")
  const [tncAgreed, toggleTncAgreed] = useState(false)

  const onSubmit = async (data) => {
    updateSaving(!saving)
    // console.log(data);
    // console.log(data.clinicLogo[0]["type"].split('/')[1]);

    try {

      let mobFloat = parseFloat(data.mobileNumber)
      let zipFloat = parseFloat(data.pincode)

      // let identityProofDocS3Key = await Storage.put(`${data.aadhaarNumber}-idProof.${data.identityProofDoc[0]["type"].split('/')[1]}`,
      //   data.identityProofDoc[0], { contentType: data.identityProofDoc[0]["type"] })
      // let identityProofDocInput = {
      //   bucket: awsconfig.aws_user_files_s3_bucket,
      //   region: awsconfig.aws_user_files_s3_bucket_region,
      //   key: identityProofDocS3Key,
      //   mimeType: data.identityProofDoc[0]["type"],
      //   localUri: data.identityProofDoc[0]["name"]
      // }

      // let registrationProofDocS3Key = await Storage.put(`${data.aadhaarNumber}-regProof.${data.registrationProofDoc[0]["type"].split('/')[1]}`,
      //   data.registrationProofDoc[0], { contentType: data.registrationProofDoc[0]["type"] })
      // let registrationProofDocInput = {
      //   bucket: awsconfig.aws_user_files_s3_bucket,
      //   region: awsconfig.aws_user_files_s3_bucket_region,
      //   key: registrationProofDocS3Key,
      //   mimeType: data.registrationProofDoc[0]["type"],
      //   localUri: data.registrationProofDoc[0]["name"]
      // }

      // let clinicAddressProofDocS3Key = null
      // let clinicAddressProofDocInput = null

      // if (data.clinicAddressProofDoc.length > 0) {
      //   clinicAddressProofDocS3Key = await Storage.put(`${data.aadhaarNumber}-clinicProof.${data.clinicAddressProofDoc[0]["type"].split('/')[1]}`,
      //     data.clinicAddressProofDoc[0], { contentType: data.clinicAddressProofDoc[0]["type"] })
      //   clinicAddressProofDocInput = {
      //     bucket: awsconfig.aws_user_files_s3_bucket,
      //     region: awsconfig.aws_user_files_s3_bucket_region,
      //     key: clinicAddressProofDocS3Key,
      //     mimeType: data.clinicAddressProofDoc[0]["type"],
      //     localUri: data.clinicAddressProofDoc[0]["name"]
      //   }
      // }

      let clinicLogoS3Key = null
      let clinicLogoInput = null

      if (data.clinicLogo && data.clinicLogo.length > 0) {
        clinicLogoS3Key = await Storage.put(`${mobFloat}-clinicLogo.${data.clinicLogo[0]["type"].split('/')[1]}`,
          data.clinicLogo[0], { contentType: data.clinicLogo[0]["type"] })
        clinicLogoInput = {
          bucket: awsconfig.aws_user_files_s3_bucket,
          region: awsconfig.aws_user_files_s3_bucket_region,
          key: clinicLogoS3Key,
          mimeType: data.clinicLogo[0]["type"],
          localUri: data.clinicLogo[0]["name"]
        }
      }

      let inputData = {
        id: mobFloat,
        mob: mobFloat,
        email: data.email,
        fName: data.firstName,
        lName: data.lastName,
        fullName: `${data.firstName} ${data.lastName}`,
        gender: data.gender,
        speciality: data.speciality,
        subSpeciality: data.subSpeciality,
        addressOne: data.building,
        addressTwo: data.street,
        country: "IND",
        state: data.state,
        city: data.city,
        zip: zipFloat,
        registrationCouncil: data.registrationCouncil,
        registrationYear: data.registrationYear,
        highestQualification: data.highestQualification,
        university: data.university,
        degreeYear: data.degreeYear,
        // identityProofDoc: identityProofDocInput,
        // registrationProofDoc: registrationProofDocInput,
        // clinicAddressProofDoc: clinicAddressProofDocInput,
        clinicLogo: clinicLogoInput,
        status: "NEW",
        experienceYears: data.experienceYears,
        registrationId: data.registrationId,
        clinicName: data.clinicName,
        currency: data.currency,
        newAptCharge: data.newAptCharge,
        followAptCharge: data.followAptCharge
      }

      // if (data.subSpeciality === "") delete inputData.subSpeciality

      for (let [key, value] of Object.entries(inputData)) {
        console.log(`!@!@ ${key}: ${value}`);
        if (value == "") delete inputData[key]
      }

      let a = await API.graphql(graphqlOperation(createDoctor, { input: inputData }))

      // console.log('inputData: ', inputData)

      API.graphql(graphqlOperation(sendSubmitEmailToDoc, { input: inputData }))

      udpateShowSuccess(true)

      console.log(a)
    }
    catch (e) {
      console.error(e)
    }
    finally {
      updateSaving(false)
    }

  }

  if (showSuccess) return <SaveSuccessMessage />

  return (

    <>
      <div style={{ textAlign: 'center', }}>
        <div style={{ position: "relative", top: "1em" }}>
          <img src="/CliniQLogo.png" style={{ width: "4em", margin: "auto", paddingTop: "1em" }} />
        </div>
        <h3 style={{ paddingTop: "1em", color: "#212F3D" }}>
          Doctor Registration Form
        </h3>
      </div>
      <div style={{ color: "#bf1650" }}><MandatoryStar />&nbsp;Note: All fields are required.</div>
      {!saving && <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset style={{ marginTop: 20 }}>
          <legend>Personal details:</legend>

          <label htmlFor="firstName">First name</label><MandatoryStar />
          <input type="text" placeholder="First name" name="firstName" ref={register({ required: "First name is required", maxLength: 100 })} />
          <ErrorMessage errors={errors} name="firstName" as="p" />

          <label htmlFor="lastName">Last name</label><MandatoryStar />
          <input type="text" placeholder="Last name" name="lastName" ref={register({ required: "Last name is required", maxLength: 100 })} />
          <ErrorMessage errors={errors} name="lastName" as="p" />

          <div style={{ display: "flex" }}>
            <label htmlFor="gender" style={{ marginRight: 20 }} style={{ display: "inline !important", marginRight: 20 }}>Gender<MandatoryStar /></label>
            <input
              name="gender"
              type="radio"
              value="male"
              ref={register({ required: "Please select your gender" })}
              style={{ display: "inline !important", marginTop: "26px", width: "auto" }}
            />
            <label
              style={{ display: "inline !important", marginRight: 20 }}
            >
              Male
            </label>
            <input
              name="gender"
              type="radio"
              value="female"
              ref={register({ required: "Please select your gender" })}
              style={{ display: "inline !important", marginTop: "26px", width: "auto" }}
            />
            <label
              style={{ marginRight: 20 }}
              style={{ display: "inline !important" }}

            >
              Female
            </label>
          </div>
          <ErrorMessage errors={errors} name="gender" as="p" />

          <label htmlFor="email">Email</label><MandatoryStar />
          <input type="email" placeholder="Email" name="email" ref={register({
            required: "Email is required", pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
              message: "Invalid email address"
            }
          })} />
          <ErrorMessage errors={errors} name="email" as="p" />

          <label htmlFor="mobileNumber">Mobile number</label><MandatoryStar />
          <input type="tel" placeholder="Mobile number" name="mobileNumber" ref={register({
            required: "Mobile number is required",
            maxLength: { value: 10, message: "Mobile number should be 10 digits" },
            minLength: { value: 10, message: "Mobile number should be 10 digits" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Mobile number should be 10 digits'
            }
          })} />
          <ErrorMessage errors={errors} name="mobileNumber" as="p" />

          {/* <label htmlFor="aadhaarNumber">Aadhaar number</label><MandatoryStar />
          <input type="text" placeholder="Aadhaar number" name="aadhaarNumber" ref={register({
            required: "Aadhaar number is required",
            maxLength: { value: 12, message: "Aadhaar number should be 12 digits" },
            minLength: { value: 12, message: "Aadhaar number should be 12 digits" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Aadhaar number should be 12 digits'
            }
          })} />
          <ErrorMessage errors={errors} name="aadhaarNumber" as="p" /> */}

          {/* <label htmlFor="identityProofDoc">Aadhaar proof</label><MandatoryStar />
          <input type="file" name="identityProofDoc" ref={register({ required: "Aadhaar proof is required" })} />
          <ErrorMessage errors={errors} name="identityProofDoc" as="p" /> */}

        </fieldset>

        <fieldset style={{ marginTop: 40 }}>
          <legend >Professional details:</legend>

          <label htmlFor="highestQualification">Highest qualification</label><MandatoryStar />
          <input type="text" placeholder="Highest qualification" name="highestQualification" ref={register({ required: "Highest qualification is required" })} />
          <ErrorMessage errors={errors} name="highestQualification" as="p" />

          <label htmlFor="university">University</label><MandatoryStar />
          <input type="text" placeholder="University" name="university" ref={register({ required: "University is required" })} />
          <ErrorMessage errors={errors} name="university" as="p" />

          <label htmlFor="degreeYear">Degree year</label><MandatoryStar />
          <input type="text" placeholder="Degree year" name="degreeYear" ref={register({
            required: "Degree year is required",
            maxLength: { value: 4, message: "Degree year should be 4 digits" },
            minLength: { value: 4, message: "Degree year should be 4 digits" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Degree year should be 4 digits'
            }
          })} />
          <ErrorMessage errors={errors} name="degreeYear" as="p" />

          <label htmlFor="speciality">Speciality</label><MandatoryStar />
          <input type="text" placeholder="Speciality" name="speciality" style={{ visibility: "hidden", height: 0, margin: 0, padding: 0 }} ref={register({ required: "Speciality is required" })} value={specialityFromAuto} />
          <Autocomplete suggestions={specialityList} updateSpecialityFromAuto={updateSpecialityFromAuto} />
          <ErrorMessage errors={errors} name="speciality" as="p" />

          {/* <label htmlFor="subSpeciality">Sub speciality</label>
          <input type="text" placeholder="Sub speciality" name="subSpeciality" ref={register} />
          <ErrorMessage errors={errors} name="subSpeciality" as="p" /> */}

          <label htmlFor="registrationId">Registration number</label><MandatoryStar />
          <input type="text" placeholder="Registration number" name="registrationId" ref={register({ required: "Registration number is required" })} />
          <ErrorMessage errors={errors} name="registrationId" as="p" />
          {/* <input type="text" placeholder="Registration #" name="registrationId" ref={register({ required: true })} style={{ marginBottom: 20, border: "1px solid #bf1650", background: "#fbecf2", borderLeft: "10px solid #ec5990" }} /> */}

          <label htmlFor="registrationCouncil">Registration council</label><MandatoryStar />
          <input type="text" placeholder="Registration council" name="registrationCouncil" ref={register({ required: "Registration council is required" })} />
          <ErrorMessage errors={errors} name="registrationCouncil" as="p" />

          <label htmlFor="registrationYear">Registration year</label><MandatoryStar />
          <input type="text" placeholder="Registration year" name="registrationYear" ref={register({
            required: "Registration year is required",
            maxLength: { value: 4, message: "Registration year should be 4 digits" },
            minLength: { value: 4, message: "Registration year should be 4 digits" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Registration year should be 4 digits'
            }
          })} />
          <ErrorMessage errors={errors} name="registrationYear" as="p" />

          <label htmlFor="experienceYears">Years of experience</label><MandatoryStar />
          <input type="text" placeholder="Years of experience" name="experienceYears" ref={register({
            required: "Years of experience is required",
            maxLength: { value: 3, message: "Please enter total years of experience" },
            minLength: { value: 1, message: "Please enter total years of experience" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Please enter total years of experience'
            }
          })} />
          <ErrorMessage errors={errors} name="experienceYears" as="p" />

          {/* <label htmlFor="registrationProofDoc">Registration proof</label><MandatoryStar />
          <input type="file" name="registrationProofDoc" ref={register({ required: "Registration proof is required" })} />
          <ErrorMessage errors={errors} name="registrationProofDoc" as="p" /> */}

        </fieldset>

        <fieldset style={{ marginTop: 40 }}>
          <legend>Clinic Details:</legend>

          <label htmlFor="clinicName">Clinic name</label><MandatoryStar />
          <input type="text" placeholder="Clinic name" name="clinicName" ref={register({ required: "Clinic name is required", maxLength: 100 })} />
          <ErrorMessage errors={errors} name="clinicName" as="p" />

          <label htmlFor="pincode">Pin code</label><MandatoryStar />
          {/* <input type="text" placeholder="Pin code" name="pincode" ref={register({ required: false, minLength: 6, maxLength: 6, pattern: /^[0-9]*$/i })} /> */}
          <input type="text" placeholder="Pin code" name="pincode" ref={register({
            required: "Pin code is required",
            maxLength: { value: 6, message: "Pin code should be 6 digits" },
            minLength: { value: 6, message: "Pin code should be 6 digits" },
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Pin code should be 6 digits'
            }
          })} />
          <ErrorMessage errors={errors} name="pincode" as="p" />

          <label htmlFor="building">Building</label><MandatoryStar />
          {/* <input type="text" placeholder="Building" name="building" ref={register({ required: false })} /> */}
          <input type="text" placeholder="Building" name="building" ref={register({ required: "Building is required" })} />
          <ErrorMessage errors={errors} name="building" as="p" />

          <label htmlFor="street">Street</label><MandatoryStar />
          {/* <input type="text" placeholder="Street" name="street" ref={register({ required: false })} /> */}
          <input type="text" placeholder="Street" name="street" ref={register({ required: "Street is required" })} />
          <ErrorMessage errors={errors} name="street" as="p" />

          <label htmlFor="city">City</label><MandatoryStar />
          {/* <input type="text" placeholder="City" name="city" ref={register({ required: false })} /> */}
          <input type="text" placeholder="City" name="city" ref={register({ required: "City is required" })} />
          <ErrorMessage errors={errors} name="city" as="p" />

          <label htmlFor="state">State</label><MandatoryStar />
          {/* <input type="text" placeholder="State" name="state" ref={register({ required: false })} /> */}
          <input type="text" placeholder="State" name="state" ref={register({ required: "State is required" })} />
          <ErrorMessage errors={errors} name="state" as="p" />

          {/* <label htmlFor="clinicAddressProofDoc">Clinic address proof</label><MandatoryStar />
          <input type="file" name="clinicAddressProofDoc" ref={register({ required: "Clinic address proof is required" })} />
          <ErrorMessage errors={errors} name="clinicAddressProofDoc" as="p" /> */}

        </fieldset>

        <fieldset style={{ marginTop: 40 }}>
          <legend>Clinic Setup Data:</legend>

          <label htmlFor="clinicLogo">Clinic Logo</label>
          <input type="file" name="clinicLogo" ref={register({ required: false })} />

          <label htmlFor="currency">Currency</label>
          <select name="currency" style={{ display: "block" }} ref={register({ required: false })}>
            <option value="INR">INR</option>
            <option value="GBP">GBP</option>
          </select>

          <label htmlFor="newAptCharge">New Appointment Charges:</label>
          <input type="text" placeholder="New Appointment Charges" name="newAptCharge" ref={register({
            pattern: {
              value: /^[0-9]*$/i,
              message: 'New Appointment Charges should be numbers only'
            }
          })} />
          <ErrorMessage errors={errors} name="newAptCharge" as="p" />

          <label htmlFor="followAptCharge">Followup Appointment Charges:</label>
          <input type="text" placeholder="Followup Appointment Charges" name="followAptCharge" ref={register({
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Followup Appointment Charges should be numbers only'
            }
          })} />
          <ErrorMessage errors={errors} name="followAptCharge" as="p" />

        </fieldset>

        <fieldset style={{ marginTop: '1em', border: "none" }}>
          <input type="checkbox" name="agreeTnc" ref={register} style={{ width: "auto", display: "inline" }} onClick={() => toggleTncAgreed(!tncAgreed)} />
          <label for="agreeTnc" style={{ width: "auto", display: "inline" }}> I agree to the <span onClick={toggle} style={{ color: "#2B867C", cursor: "pointer" }}><u>terms and conditions</u></span> of use</label>

          {/* <button className="button-default" onClick={toggle}>Show Modal</button> */}
          <Tnc
            isShowing={isShowing}
            hide={toggle}
          />

          <input type="submit" disabled={!tncAgreed} />
        </fieldset>

      </form>
      }

      {saving && <SavingSpinnerSvg />}
    </>
  );

}
